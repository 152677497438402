body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  position: relative;
  overflow-x: hidden;
}


*,
*:before,
*:after {
  box-sizing: inherit;
}


.u-lock-scroll {
  overflow: hidden !important;
}


ul {
  margin: 0;
}
